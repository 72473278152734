import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 228px;
`;

export const SliderWrapper = styled.div`
  position: absolute;
  width: 192px;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
`;
