import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { XCircle } from 'react-feather';
import EditableTemplateName from '../../../BuildEmailTemplateModal/components/EditableTemplateName';

function Header({ title, onCancel, onEdit }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        background: '#fff',
      }}
    >
      {onEdit ? (
        <EditableTemplateName defaultName={title} onBlur={onEdit} />
      ) : (
        <Typography sx={{ fontWeight: 600 }} variant="p">{title}</Typography>
      )}
      <XCircle
        size={18}
        color="#848484"
        onClick={onCancel}
        style={{ cursor: 'pointer' }}
      />
    </Box>
  );
}

Header.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default Header;
