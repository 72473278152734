export const styles = {
  container: {
    width: '100%',
    height: '100%',
  },
  innerContainer: {
    width: '100%',
    maxWidth: 458,
    height: '100%',
    bgcolor: 'background.default',
    ml: 'auto',
    mr: 'auto',
    mb: 2,
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: 200,
    minWidth: 280,
    bgcolor: 'background.default',
    border: 1,
    borderColor: 'grey.400',
    borderRadius: 1,
  },
  messageItem: {
    lineHeight: '16px',
    fontSize: 14,
    p: 1,
    width: 'fit-content',
    bgcolor: '#e2e2e2',
    ml: 0,
    color: 'text.primary',
    borderRadius: '12px 12px 12px 0px',
  },
  currentMessage: {
    maxHeight: 200,
    borderTop: 1,
    borderColor: 'grey.400',
    overflow: 'auto',
  },
  textarea: {
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
};
