import React from 'react';
import PropTypes from 'prop-types';
import ImageCropper from '../ImageCropper';
import { X } from 'react-feather';
import { DeleteButtonWrapper, ErrorWrapper } from './style';
import { ErrorMessage } from '../../../../globals';
import { Button, Card, Typography } from '@mui/material';

function UploadForm({
  uploadedImage,
  setUploadedImage,
  setCroppedAreaPixels,
  error,
}) {
  const inputFileRef = React.useRef(null);

  const uploadImage = () => {
    inputFileRef.current.click();
  };

  const removeBrandingLogo = () => {
    setUploadedImage(null);
  };

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        p: 4,
      }}
    >
      {uploadedImage && (
        <DeleteButtonWrapper
          data-testid="BrandingPage/DeleteButton"
          onClick={removeBrandingLogo}
        >
          <X />
        </DeleteButtonWrapper>
      )}
      {uploadedImage ? (
        <ImageCropper
          uploadedImage={uploadedImage}
          setCroppedAreaPixels={setCroppedAreaPixels}
        />
      ) : (
        <>
          <Button
            aria-label="upload image"
            variant="contained"
            onClick={uploadImage}
          >
            Upload image
          </Button>
          <Typography variant="body2" sx={{ pt: 2 }}>
            Works with png, svg, jpg, gif
          </Typography>
        </>
      )}
      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
      {error && (
        <ErrorWrapper>
          <ErrorMessage data-testid="UploadForm/ErrorMessage">
            {error}
          </ErrorMessage>
        </ErrorWrapper>
      )}
    </Card>
  );
}

UploadForm.propTypes = {
  uploadedImage: PropTypes.string,
  setUploadedImage: PropTypes.func.isRequired,
  setCroppedAreaPixels: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default UploadForm;
