import copyObject from 'fast-copy';

export function initState() {
  const urlParams = new URLSearchParams(window.location.search);
  const autopilotResponseFilter = urlParams.get('filter') || 'all';

  return {
    messages: [],
    conversation: [],
    conversationFetched: false,
    globalTotalMessages: '',
    currentMessage: null,
    error: null,
    autopilotResponseFilter,
  };
}

export function reducer(state, action) {
  const nextState = copyObject(state);

  switch (action.type) {
    case 'fetchMessages':
      const messages = action.payload.messages;
      const nextMessage = messages[0];
      const currentMessage = state.currentMessage;

      nextState.conversation = [];
      nextState.conversationFetched = false;
      nextState.messages = messages;
      nextState.globalTotalMessages = action.payload.total;

      if (!nextMessage) {
        nextState.currentMessage = null;
      } else if (!currentMessage) {
        nextState.currentMessage = nextMessage;
      } else if (nextMessage.id !== currentMessage.id) {
        nextState.currentMessage = nextMessage;
      }

      return nextState;
    case 'actOnMessage':
      const nextMessages = nextState.messages.slice(1);

      return {
        ...nextState,
        messages: nextMessages,
        currentMessage: copyObject(nextMessages[0]) || null,
        globalTotalMessages: state.globalTotalMessages - 1,
        conversation: [],
        conversationFetched: false,
        error: null,
      };
    case 'changeTexts':
      const texts = action.payload.texts;

      return {
        ...nextState,
        currentMessage: {
          ...state.currentMessage,
          manual: texts !== state.messages[0].texts,
          texts,
        },
      };
    case 'setConversation':
      if (
        action.payload.sessionId !==
        (state.currentMessage && state.currentMessage.autopilotSessionId)
      ) {
        return state;
      } else {
        return {
          ...nextState,
          conversation: action.payload.conversation,
          conversationFetched: true,
        };
      }
    case 'setError':
      if (state.error === action.payload.message) {
        return state;
      } else {
        return { ...nextState, error: action.payload.message };
      }
    case 'removeError':
      if (state.error === null) {
        return state;
      } else {
        return { ...nextState, error: null };
      }
    case 'resetConversation':
      return { ...nextState, conversation: [], conversationFetched: false };
    case 'reset':
      return {
        ...nextState,
        messages: [],
        currentMessage: null,
        conversation: [],
        conversationFetched: false,
        error: null,
        globalTotalMessages: 0,
      };
    default:
      throw new Error();
  }
}
