import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function EditableTemplateName({ defaultName, onBlur }) {
  const [name, setName] = React.useState(defaultName);
  const [editState, setEditState] = React.useState(false);
  const [inputWidth, setInputWidth] = React.useState(5);
  const inputRef = React.useRef(null);
  const hiddenSpanRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current && editState) {
      inputRef.current.focus();
    }
  }, [editState]);

  React.useEffect(() => {
    if (hiddenSpanRef.current) {
      setInputWidth(hiddenSpanRef.current.offsetWidth + 1);
    }
  }, [name]);

  const onNameBlur = () => {
    if (defaultName === name) {
      setEditState(false);
    } else if (!name.length) {
      setName(defaultName);
      setEditState(false);
    } else {
      onBlur(name);
      setEditState(false);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onNameBlur();
    }
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 40 }}>
      <Box
        component="span"
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: '-10',
          overflow: 'hidden',
          whiteSpace: 'pre',
          fontSize: '1rem',
          fontWeight: 600,
        }}
        ref={hiddenSpanRef}
      >
        {name}
      </Box>
      <Box sx={{ mb: 1, mt: 1 }}>
        {editState ? (
          <Input
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              height: 20,
              letterSpacing: 'normal',
            }}
            inputProps={{
              sx: {
                width: `${inputWidth}px`,
                '&:focus': { boxShadow: 'none' },
              },
              'aria-label': 'template name',
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onBlur={onNameBlur}
            inputRef={inputRef}
            onKeyPress={onKeyPress}
          />
        ) : (
          <Typography sx={{ fontWeight: 600 }} variant="p">
            {name}
          </Typography>
        )}
      </Box>
      {!editState && (
        <Box sx={{ ml: 1 }}>
          <IconButton size="small" onClick={() => setEditState(true)}>
            <EditIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
