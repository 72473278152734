import React from 'react';
import _compact from 'lodash/compact';
import FeePercentageText from './components/FeePercentageText';

export default function useCostSectionsList(
  phoneNumberPrice,
  smsPrice,
  mmsPrice,
  platformPercentFee,
  platformUsBankAccountFeePercentage,
  platformUsBankAccountFeeMin,
  platformUsBankAccountFeeMax,
) {
  const list = _compact([
    phoneNumberPrice && {
      title: 'Phone Numbers',
      price: phoneNumberPrice,
      frequency: '/ phone number',
    },
    smsPrice && {
      title: 'SMS',
      price: smsPrice,
      frequency: '/ SMS segment',
    },
    mmsPrice && {
      title: 'MMS',
      price: mmsPrice,
      frequency: '/ MMS segment',
    },
    platformPercentFee && {
      title: 'Card Transaction Fee',
      price: <FeePercentageText percentage={platformPercentFee} />,
    },
    platformUsBankAccountFeePercentage && {
      title: 'ACH Transaction Fee',
      price: (
        <FeePercentageText
          percentage={platformUsBankAccountFeePercentage}
          feeMin={platformUsBankAccountFeeMin}
          feeMax={platformUsBankAccountFeeMax}
        />
      ),
    },
  ]);

  return list;
}
