import React from 'react';
import ReactEmailEditor from 'react-email-editor';

export default function EmailEditor({
  init,
  emailEditorRef,
  customJS,
  onReady,
}) {
  return (
    <ReactEmailEditor
      ref={emailEditorRef}
      onReady={onReady}
      minHeight="90%"
      options={{
        projectId: init.project_id,
        user: {
          id: init.user_id,
          signature: init.signature,
        },
        customCSS: [
          `
              html {
                overflow: auto;
              }
            `,
        ],
        customJS,
        designTags: init.design_tags,
        fonts: {
          showDefaultFonts: false,
          customFonts: init.custom_fonts,
        },
        features: {
          colorPicker: {
            presets: init.palette_colors,
          },
        },
      }}
      style={{
        maxWidth: '100%',
        minHeight: '100%',
        marginTop: '36px',
        flex: '1',
        backgroundColor: '#F2F2F2',
      }}
    />
  );
}
