import React from 'react';
import Box from '@mui/material/Box';

export default function Footer({ children }) {
  return (
    <Box
      sx={{
        padding: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}
