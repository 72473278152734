import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import ReportIcon from '@mui/icons-material/Report';
import InboxIcon from '@mui/icons-material/Inbox';
import MessageIcon from '@mui/icons-material/Message';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  Box,
  Button,
  ButtonGroup,
  Select,
  Stack,
  MenuItem,
  FormControl,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
} from '@mui/material';
import { styles } from './style';
import {
  getAutopilotResponses,
  approveAutopilotResponse,
  ignoreAutopilotResponse,
  ignoreAllInitialResponses,
  getAutopilotSessions,
  autopilotSessionOptOut,
} from '../../api';
import { reducer, initState } from './reducer';
import { useSnackbar } from 'notistack';

const menuItems = [
  { icon: <InboxIcon />, title: 'All Outbox', value: 'all' },
  { icon: <MessageIcon />, title: 'Initial Sends', value: 'initial' },
  { icon: <ReplyIcon />, title: 'Replies', value: 'replies' },
];

function Outbox() {
  const [buttonIsDisabled, setButtonIsDisabled] = React.useState(false);
  const messagesListRef = useRef(null);
  const [state, dispatch] = React.useReducer(reducer, initState());
  const {
    messages,
    conversation,
    globalTotalMessages,
    autopilotResponseFilter,
    conversationFetched,
    currentMessage,
    error,
  } = state;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchAutopilotResponses();
  }, []);

  useEffect(() => {
    if (currentMessage && currentMessage.texts === '') {
      dispatch({
        type: 'setError',
        payload: {
          message: 'Send a response or ignore',
        },
      });
    } else {
      dispatch({ type: 'removeError' });
    }
  }, [currentMessage && currentMessage.texts]);

  useEffect(() => {
    if (currentMessage !== null && conversationFetched === false) {
      fetchConversation(currentMessage.autopilotSessionId);
    }
  }, [currentMessage]);

  const prepareMessages = ({ id, attributes }) => ({
    id,
    ...attributes,
    texts: attributes.texts.join('\n'),
  });

  const prepareParams = () => {
    if (autopilotResponseFilter === 'initial') {
      return { initial: true };
    } else if (autopilotResponseFilter === 'replies') {
      return { initial: false };
    } else {
      return {};
    }
  };

  const fetchConversation = (autopilotSessionId) => {
    getAutopilotSessions(autopilotSessionId)
      .then(({ data }) => {
        dispatch({
          type: 'setConversation',
          payload: {
            sessionId: autopilotSessionId,
            conversation: data.map(prepareMessages),
          },
        });

        if (data.length > 1 && messagesListRef.current) {
          const lastMessage = messagesListRef.current.children[data.length - 2];
          if (lastMessage) {
            lastMessage.scrollIntoView();
          }
        }
      })
      .catch(handleErrorOnLoadConversation);
  };

  const fetchAutopilotResponses = () => {
    const params = prepareParams();

    getAutopilotResponses(params)
      .then(({ data, meta: { total } }) => {
        const messages = data.map(prepareMessages);
        dispatch({
          type: 'fetchMessages',
          payload: { messages, total },
        });
      })
      .catch(resetAndRenderError);
  };

  const resetAndRenderError = () => {
    dispatch({ type: 'reset' });
    enqueueSnackbar(
      'Something went wrong. Try refreshing the page. If there is still an error please contact the Experience team.',
      { variant: 'error', autoHideDuration: 3000 },
    );
  };

  const handleErrorOnLoadConversation = () => {
    dispatch({ type: 'resetConversation' });
    enqueueSnackbar(
      "Can't load conversation. Try refreshing the page. If there is still an error please contact the Experience team.",
      { variant: 'error', autoHideDuration: 3000 },
    );
  };

  const actOnMessage = (action, id) => () => {
    setButtonIsDisabled(true);

    const params = {};
    if (currentMessage.manual) {
      params.manual = currentMessage.manual;
      params.texts = currentMessage.texts;
    }

    action(id, params)
      .then(() => {
        if (action === autopilotSessionOptOut) {
          fetchAutopilotResponses();
        } else {
          dispatch({ type: 'actOnMessage' });
        }
      })
      .catch((e) => {
        const perrors = JSON.parse(e.message);

        if (perrors.errors) {
          const errors = Object.values(perrors.errors).join(', ');

          dispatch({
            type: 'setError',
            payload: { message: errors },
          });
        } else {
          throw `Not supported errors: ${e.message}`;
        }
      })
      .finally(() => {
        setButtonIsDisabled(false);
      });
  };

  const handleTextareChange = (e) => {
    const texts = e.currentTarget.value;
    dispatch({ type: 'changeTexts', payload: { texts } });
  };

  const handleIgnoreAll = () => {
    ignoreAllInitialResponses()
      .then(() => {
        location.reload();
      })
      .catch(() => {
        enqueueSnackbar("Can't ignore all initial messages", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const handleChange = (e) => {
    const url = new URL(window.location.href);
    url.searchParams.set('filter', e.target.value);

    window.location.href = url.href;
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const selectRenderValue = (value) => {
    const itemLabel = menuItems.find((item) => item.value === value).title;
    const messagecCount = numberWithCommas(globalTotalMessages);

    return `${itemLabel} (${messagecCount})`;
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Stack direction="row" alignItems="center" justifyContent="end" mb={1}>
          {!isEmpty(currentMessage) && (
            <Typography variant="body2" mr="auto" letterSpacing="1px">
              TO: {currentMessage.to}
            </Typography>
          )}
          {autopilotResponseFilter === 'initial' && (
            <Button
              onClick={() => {
                window.confirm(
                  'Are you sure you want to delete all initial messages?',
                ) && handleIgnoreAll();
              }}
              disabled={messages.length < 1}
              variant="outlined"
              size="small"
            >
              Ignore All
            </Button>
          )}
          <FormControl sx={{ minWidth: 50 }} size="small">
            <Select
              aria-label="filter"
              renderValue={selectRenderValue}
              value={autopilotResponseFilter}
              onChange={handleChange}
            >
              {menuItems.map(({ title, value, icon }) => (
                <MenuItem key={value} value={value}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{title}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Box mb={2} sx={styles.chatContainer}>
          {!isEmpty(currentMessage) && (
            <Typography
              variant="h5"
              mt={2}
              sx={{ textAlign: 'center', textTransform: 'uppercase' }}
            >
              {currentMessage.experienceTitle}
            </Typography>
          )}
          <Stack
            m={2}
            height="100%"
            overflow="auto"
            data-testid="Outbox/MessagesList"
            ref={messagesListRef}
          >
            {conversation.map((message) => (
              <React.Fragment key={message.id}>
                {message.originText && message.originText.length > 0 && (
                  <Box sx={styles.messageItem}>{message.originText}</Box>
                )}
                {message.status === 'approved' && (
                  <Box
                    sx={{
                      ...styles.messageItem,
                      bgcolor: '#66aef9',
                      ml: 'auto',
                      color: '#fff',
                      borderRadius: '12px 12px 0 12px',
                      alignSelf: 'end',
                    }}
                  >
                    {message.texts}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Stack>
          <Box sx={styles.currentMessage}>
            {isEmpty(currentMessage) ? (
              <Typography p={2} variant="body2" fontSize={12} fontWeight={500}>
                No message to approve...
              </Typography>
            ) : (
              <Box>
                <TextField
                  sx={styles.textarea}
                  onChange={handleTextareChange}
                  value={currentMessage.texts}
                  placeholder="Type your response..."
                  multiline
                  fullWidth
                  error={error}
                  helperText={error}
                />
              </Box>
            )}
          </Box>
        </Box>
        <ButtonGroup variant="contained" fullWidth>
          {messages.length < 1 ? (
            <Button variant="contained" onClick={fetchAutopilotResponses}>
              Load More
            </Button>
          ) : (
            <>
              <Button
                aria-label="opt out"
                onClick={actOnMessage(
                  autopilotSessionOptOut,
                  currentMessage.autopilotSessionId,
                )}
                disabled={buttonIsDisabled}
              >
                <ReportIcon />
              </Button>
              <Button
                aria-label="ignore"
                onClick={actOnMessage(
                  ignoreAutopilotResponse,
                  currentMessage.id,
                )}
                disabled={buttonIsDisabled}
              >
                Ignore
              </Button>
              <Button
                aria-label="send text"
                onClick={actOnMessage(
                  approveAutopilotResponse,
                  currentMessage.id,
                )}
                disabled={
                  buttonIsDisabled ||
                  (currentMessage && currentMessage.texts === '')
                }
              >
                Send Text
              </Button>
            </>
          )}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default Outbox;
