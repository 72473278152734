import styled from 'styled-components';

export const Logo = styled.div`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  background-image: url(${(props) => props.logoUrl});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 7px;
`;

export const Title = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  flex: 1;
  margin-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

