import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import PlanCard from './components/PlanCard';
import { Alert, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import {
  getBillingPlans,
  changeBillingPlan,
  cancelBillingPlanDowngrade,
  getBillingActiveSubscription,
} from '../../api';
import BillingOverview from './components/BillingOverview';

const DowngradeInfo = (handleClick, nextPlanName, currentPeriodEnd) => (
  <>
    Your subscription will be downgraded to {nextPlanName} at {currentPeriodEnd}
    .{' '}
    <Button onClick={handleClick} size="small">
      Cancel Downgrade
    </Button>
  </>
);

function BillingPage() {
  const [plans, setPlans] = React.useState(null);
  const [manageLink, setManageLink] = React.useState(null);
  const [cancelLink, setCancelLink] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [currentPlan, setCurrentPlan] = React.useState(null);
  const [nextPlan, setNextPlan] = React.useState(null);
  const [activeSubscription, setActiveSubscription] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const loadPlans = () => {
    const subPromise = new Promise((resolve, _) => {
      getBillingActiveSubscription().then(({ data }) => {
        if (data) {
          setActiveSubscription(data.attributes);
        }

        resolve();
      });
    });

    const billingPromise = new Promise((resolve, _) => {
      getBillingPlans().then(({ data, meta }) => {
        const plans = data.map(({ attributes }) => {
          if (attributes.state === 'current') {
            setCurrentPlan(attributes);
          } else if (attributes.state === 'next') {
            setNextPlan(attributes);
          }
          return {
            ...attributes,
            loading: false,
            disabled: false,
          };
        });

        setPlans(plans);
        setManageLink(meta?.manage_path);
        setCancelLink(meta?.cancel_path);
        resolve();
      });
    });

    return [subPromise, billingPromise];
  };

  const handleError = (error) => {
    const messages = JSON.parse(error.message).errors;

    for (const key of Object.keys(messages)) {
      switch (key) {
        case 'card':
          enqueueSnackbar(messages.card[0], {
            variant: 'error',
            persist: true,
            action: (key) => (
              <>
                <form action={manageLink} method="post">
                  <Button size="small" type="submit">
                    Please update your card here.
                  </Button>
                </form>
              </>
            ),
          });
          break;
        default:
          throw new Error('Unsupported error');
      }
    }
  };
  const handleCancelDowngrade = () => {
    cancelBillingPlanDowngrade(cancelLink).then(() => { loadPlans(); });
  };

  const changePlan = (planId) => () => {
    const nextPlans = plans.map((plan) => ({
      ...plan,
      disabled: true,
      loading: planId === plan.id,
    }));
    setPlans(nextPlans);

    changeBillingPlan(planId)
      .then(() => loadPlans())
      .catch((err) => {
        setPlans(_cloneDeep(plans));
        handleError(err);
      });
  };

  React.useEffect(() => {
    loadPlans();
  }, []);

  React.useEffect(() => {
    if (cancelLink && nextPlan && activeSubscription) {
      setAlertMessage(
        DowngradeInfo(
          handleCancelDowngrade,
          nextPlan.name,
          activeSubscription.currentPeriodEnd,
        ),
      );
    } else {
      setAlertMessage(null);
    }
  }, [cancelLink, nextPlan, activeSubscription]);

  if (!plans) {
    return null;
  }

  return (
    <>
      {alertMessage && (
        <Alert severity="info" sx={{ alignItems: 'center' }}>
          {alertMessage}
        </Alert>
      )}
      {currentPlan && activeSubscription && (
        <BillingOverview
          manageLink={manageLink}
          planCounter={currentPlan.usageLimit}
          activeSubscription={activeSubscription}
        />
      )}
      <Typography variant="h4" mb={2}>
        Plans
      </Typography>
      <Grid container spacing={2}>
        {plans.map(
          ({
            id,
            name,
            price,
            state,
            disabled,
            loading,
            usageName,
            usageLimit,
            free,
            phoneNumberPrice,
            platformPercentFee,
            platformUsBankAccountFeePercentage,
            platformUsBankAccountFeeMin,
            platformUsBankAccountFeeMax,
            smsPrice,
            mmsPrice,
          }) => (
            <Grid key={id} xs={12} md={6} lg={4}>
              <PlanCard
                id={id}
                title={name}
                price={price}
                free={free}
                state={state}
                changePlan={changePlan(id)}
                disabled={disabled}
                isLoading={loading}
                withSubscription={!!manageLink}
                usageName={usageName}
                usageLimit={usageLimit}
                phoneNumberPrice={phoneNumberPrice}
                platformPercentFee={platformPercentFee}
                platformUsBankAccountFeePercentage={platformUsBankAccountFeePercentage}
                platformUsBankAccountFeeMin={platformUsBankAccountFeeMin}
                platformUsBankAccountFeeMax={platformUsBankAccountFeeMax}
                smsPrice={smsPrice}
                mmsPrice={mmsPrice}
              />
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
}

export default BillingPage;
