import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

function Item({
  id,
  name,
  description,
  previewUrl,
  selected,
  onClick,
}) {
  const borderColor = selected ? '#ff4200' : 'transparent';

  return (
    <Box
      sx={{
        width: 178,
        cursor: 'pointer',
        overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Box sx={{ width: 178, height: 178, bgcolor: '#fafafa', borderRadius: 2 }}>
        {id === 'new' ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderStyle: 'solid',
              borderRadius: 2,
              borderWidth: 2,
              borderColor,
            }}
          >
            <AddIcon sx={{ opacity: 0.5 }} />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderStyle: 'solid',
              borderRadius: 2,
              borderWidth: 2,
              borderColor,
            }}
            component="img"
            src={previewUrl}
          />
        )}
      </Box>
      <Typography sx={{ mt: 1, fontSize: 12 }} component="p">
        {name}
      </Typography>
      <Typography sx={{ opacity: 0.5, fontSize: 12 }} component="p">
        {description}
      </Typography>
    </Box>
  );
}

export default function TemplatesList({ items, selected, setSelected }) {
  return (
    <Grid container spacing={2}>
      {items.map(({ id, name, description, previewUrl }) => (
        <Grid key={id} item>
          <Item
            id={id}
            name={name}
            description={description}
            previewUrl={previewUrl}
            selected={selected === id}
            onClick={() => setSelected(id)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
