import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--upload-media-background);
  border: 1px solid var(--border-color-primary);
  border-radius: 8px;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  max-width: 168px;
  margin-top: 28px;
`;

export const HelperText = styled.p`
  font-size: 14px;
  color: var(--font-color-secondary--light);
  text-align: center;
  margin-bottom: 40px;
`;

export const Logo = styled.div`
  width: 104px;
  height: 104px;
  background-color: #000;
  background-image: url(${(props) => props.logoUrl});
  background-size: cover;
  background-position: center;
  border: 1px dashed #727278;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  color: var(--font-color-secondary--light);
  cursor: pointer;
  z-index: 1;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: 8px;
`;
