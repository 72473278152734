import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

function CostSection({ title, price, frequency }) {
  return (
    <Box sx={{ my: 2 }} data-testid="CostSection/Container">
      <Typography variant="h6">{title}</Typography>
      <Box display="inline-block">
        <span>{price} </span>
        <Box component="span" sx={{ color: 'text.light', fontSize: 13 }}>
          {frequency}
        </Box>
      </Box>
    </Box>
  );
}

CostSection.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  frequency: PropTypes.string,
};

export default CostSection;
