import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader';
import useWorkspace from '../../../../hooks/useWorkspace';

import { Button, Card, Divider, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useCostSectionsList from './useCostSectionsList';
import CostSection from './components/CostSection';

function PlanCard({
  id,
  title,
  price,
  state,
  disabled,
  isLoading,
  withSubscription,
  changePlan,
  usageName,
  usageLimit,
  free,
  phoneNumberPrice,
  platformPercentFee,
  platformUsBankAccountFeePercentage,
  platformUsBankAccountFeeMin,
  platformUsBankAccountFeeMax,
  smsPrice,
  mmsPrice,
}) {
  const workspaceId = useWorkspace();
  const isCurrent = state === 'current';
  const submitLink = `/${workspaceId}/settings/billing_plans/${id}/checkout`;
  const costSections = useCostSectionsList(
    phoneNumberPrice,
    smsPrice,
    mmsPrice,
    platformPercentFee,
    platformUsBankAccountFeePercentage,
    platformUsBankAccountFeeMin,
    platformUsBankAccountFeeMax,
  );

  const cta = useMemo(() => {
    if (isCurrent) {
      return (
        <>
          <Button
            aria-label="select plan"
            variant="outlined"
            startIcon={<CheckIcon />}
            disabled
            fullWidth
          >
            Current Plan
          </Button>
        </>
      );
    }

    if (withSubscription || free) {
      return (
        <>
          <Button
            variant="contained"
            aria-label="select plan"
            onClick={changePlan}
            disabled={disabled}
            fullWidth
          >
            Choose Plan
            {isLoading && <Loader loader={{ color: 'white' }} />}
          </Button>
        </>
      );
    }

    return (
      <form action={submitLink} method="post">
        <Button
          aria-label="select plan"
          type="submit"
          disabled={disabled}
          variant="contained"
          fullWidth
        >
          Choose Plan
        </Button>
      </form>
    );
  }, [isCurrent, disabled, submitLink, isLoading, withSubscription]);

  return (
    <Card
      data-testid="PlanCard/Container"
      sx={{
        p: 2,
        borderColor: isCurrent ? 'primary.main' : 'primary',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h3"
        sx={{ fontWeight: 600 }}
        data-testid="PlanCard/Title"
      >
        {title}
      </Typography>
      <Box sx={{ my: 2 }}>
        <Box>
          <Box
            component="span"
            sx={{ fontSize: '2rem', mr: 0.5 }}
            data-testid="PlanCard/Price"
          >
            {price}
          </Box>
          <Box component="span" sx={{ color: 'text.light', fontSize: 13 }}>
            / month
          </Box>
        </Box>
        <Box sx={{ display: 'inline-block' }} data-testid="PlanCard/UsageInfo">
          <span>{usageLimit} </span>
          <span>{usageName} </span>
          <Box component="span" sx={{ color: 'text.light', fontSize: 13 }}>
            / month
          </Box>
        </Box>
      </Box>
      {costSections.map(({ price, title, frequency }) => (
        <React.Fragment key={title}>
          <Divider />
          <CostSection
            price={price}
            title={title}
            frequency={frequency}
          />
        </React.Fragment>
      ))}
      <Box sx={{ mt: 'auto' }}>{cta}</Box>
    </Card>
  );
}

PlanCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.string,
  state: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  withSubscription: PropTypes.bool,
  changePlan: PropTypes.func,
  usageName: PropTypes.string,
  usageLimit: PropTypes.string,
  free: PropTypes.bool,
  phoneNumberPrice: PropTypes.string,
  platformPercentFee: PropTypes.string,
  smsPrice: PropTypes.string,
  mmsPrice: PropTypes.string,
};

export default PlanCard;
