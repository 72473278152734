import React from 'react';
import Box from '@mui/material/Box';

const buildFeeMinMaxText = (feeMin, feeMax) => {
  if (feeMin && feeMax) {
    return `; capped at ${feeMax}, minimum ${feeMin}`;
  }

  if (feeMax) {
    return `; capped at ${feeMax}`;
  }

  if (feeMin) {
    return `; minimum ${feeMin}`;
  }
};

function StyledText({ percentage, text }) {
  return (
    <>
      <Box component="span">{percentage}%</Box>{' '}
      <Box component="span" sx={{ color: 'text.light', fontSize: 13 }}>
        plus Stripe fees{text}
      </Box>
    </>
  );
}

export default function FeePercentageText({ percentage, feeMin, feeMax }) {
  return (
    <StyledText
      percentage={percentage}
      text={buildFeeMinMaxText(feeMin, feeMax)}
    />
  );
}
