import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider, { sliderClasses } from '@mui/material/Slider';
import Cropper from 'react-easy-crop';
import { Container, SliderWrapper } from './style';

const sliderStyles = {
  [`& .${sliderClasses.root}`]: {
    color: 'var(--primary-color)',
    height: 3,
    width: '100%',
  },
  [`& .${sliderClasses.thumb}`]: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    [`&:focus, &:hover, &.${sliderClasses.active}`]: {
      boxShadow: 'inherit',
    },
  },
  [`& .${sliderClasses.valueLabel}`]: {
    left: 'calc(-50% + 4px)',
  },
  [`& .${sliderClasses.track}`]: {
    backgroundColor: 'var(--primary-color)',
    height: 3,
    borderRadius: '27px',
    border: 0,
  },
  [`& .${sliderClasses.rail}`]: {
    height: 3,
    borderRadius: '27px',
    color: '#fff',
  },
};

function ImageCropper({ uploadedImage, setCroppedAreaPixels }) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Container>
      <Cropper
        image={uploadedImage}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        showGrid={false}
        onZoomChange={setZoom}
        style={{
          cropAreaStyle: {
            borderRadius: '50%',
          },
        }}
      />
      <SliderWrapper>
        <Slider
          sx={sliderStyles}
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_e, zoom) => setZoom(zoom)}
        />
      </SliderWrapper>
    </Container>
  );
}

ImageCropper.propTypes = {
  uploadedImage: PropTypes.string.isRequired,
  setCroppedAreaPixels: PropTypes.func.isRequired,
};

export default ImageCropper;
