import React from 'react';
import PropTypes from 'prop-types';
import { Logo, Title } from './style';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function LogoPreview({ logoUrl, title, setLogoAttached}) {
  return (
    <Card sx={{display: 'flex', alignItems: 'center', p: 1}} data-testid="LogoPreview/Container">
      <Logo logoUrl={logoUrl} data-testid="LogoPreview/Logo" />
      <Title data-testid="LogoPreview/Title">
        {title}
      </Title>
      <IconButton
        onClick={() => setLogoAttached(false)}
        aria-label='delete logo'
      >
        <CloseIcon/>
      </IconButton>
    </Card>
  );
};

LogoPreview.propTypes = {
  logoUrl: PropTypes.string,
  title: PropTypes.string,
  setLogoAttached: PropTypes.func.isRequired,
};

export default LogoPreview;
