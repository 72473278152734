import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

function BillingOverview({ planCounter, manageLink, activeSubscription }) {
  const {
    viewsUsage,
    smsUsage,
    mmsUsage,
    currentPeriodEnd,
    phoneNumbersUsage,
  } = activeSubscription;

  const countersList = [
    {
      subtitle: 'Block Views',
      text: `You've used ${viewsUsage || 0} of ${planCounter}`,
      additionalText: `(Resets on ${currentPeriodEnd})`,
    },
    {
      subtitle: 'active numbers',
      text: `${phoneNumbersUsage || 0} / month`,
    },
    {
      subtitle: 'SMS count',
      text: `${smsUsage} / month`,
    },
    {
      subtitle: 'MMS count',
      text: `${mmsUsage} / month`,
    },
  ];

  return (
    <Box data-testid="BillingOverview/Container">
      <Typography variant="h4">Billing Overview</Typography>
      {countersList.map(({ subtitle, text, additionalText }) => (
        <Box sx={{ display: 'inline-block', mr: 2 }} key={subtitle}>
          <Box
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              fontWeight: 500,
              display: 'inline',
            }}
          >
            {subtitle}
          </Box>{' '}
          <Box sx={{ display: 'inline', fontSize: '0.75rem' }}>{text}</Box>{' '}
          {additionalText && (
            <Box
              sx={{
                display: 'inline',
                fontSize: '0.75rem',
                color: 'text.light',
              }}
            >
              {additionalText}
            </Box>
          )}
        </Box>
      ))}
      {manageLink && (
        <Box my={2}>
          <form action={manageLink} method="post">
            <Button type="submit" variant="outlined">
              Edit Billing Info
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
}

BillingOverview.propTypes = {
  planCounter: PropTypes.string,
  manageLink: PropTypes.string,
  activeSubscription: PropTypes.shape({
    viewsUsage: PropTypes.string,
    smsUsage: PropTypes.number,
    mmsUsage: PropTypes.number,
    currentPeriodEnd: PropTypes.string,
    phoneNumbersUsage: PropTypes.number,
  }),
};

export default BillingOverview;
